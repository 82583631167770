import React from "react"
import { rhythm, scale } from "../utilities/typography"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import MCard from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import CardActions from "@material-ui/core/CardActions"
import CardActionArea from "@material-ui/core/CardActionArea"
import Chip from "@material-ui/core/Chip"
import { Typography } from "@material-ui/core"
import rendererOptions from "../utilities/rendererOptions"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const truncateNode = (node, maxChars = 2000) => {
  let chars = 0
  const go = (n) => {
    if (n.nodeType === "text") {
      const text = n.value
      const remainingChars = maxChars - chars
      if (remainingChars <= 0) {
        return { ...n, value: "" }
      }
      if (text.length <= remainingChars) {
        chars += text.length
        return n
      }
      const truncatedText = text.slice(0, remainingChars)
      chars += truncatedText.length
      return { ...n, value: `${truncatedText}...` }
    }
    if (n.content) {
      const truncatedContent = []
      for (const child of n.content) {
        const truncatedChild = go(child)
        truncatedContent.push(truncatedChild)
        if (chars >= maxChars) {
          n.content = truncatedContent
          break
        }
      }
    }
    return n
  }
  const truncated = go(node)
  return { truncated, chars }
}

// truncateNodes takes as input an array of JSON objects, each representing a
// Contentful rich text field and returns a new array of JSON objects, with the
// same structure, but with a maximum number of characters. The returned list
// has no more than maxChars characters in it. truncateNodes recursively traverses
// the JSON objects and stops when it reaches the maximum number of characters.
const truncateNodes = (nodes, maxChars = 2000) => {
  let chars = 0
  const truncatedNodes = []
  for (const node of nodes) {
    const { truncated, chars: nodeChars } = truncateNode(node, maxChars - chars)
    chars += nodeChars
    truncatedNodes.push(truncated)
    if (chars >= maxChars) {
      break
    }
  }
  return truncatedNodes
}

export const BlogEntry = ({
  title,
  text,
  date,
  urlTitle,
  type,
  handleClickChip,
  displayLabel,
  heroImage,
}) => {
  const json = JSON.parse(text.body.raw)
  // recursively remove all nodes with type 'embedded-asset-block' from the json
  // this is a workaround for a bug in gatsby-source-contentful
  const remove = (node) => {
    if (node.nodeType === "embedded-asset-block") {
      return false
    }
    if (node.content) {
      node.content = node.content.filter(remove)
    }
    return true
  }
  const removed = json.content.filter(remove)
  const newBody = {
    raw: JSON.stringify({ content: truncateNodes(removed, 250) }),
  }

  const rendered = renderRichText(newBody, rendererOptions)
  return (
    <MCard
      css={`
        display: flex;
        flex-direction: column;
      `}
    >
      <CardActionArea
        css={`
          flex-grow: 1;
        `}
      >
        <Link
          to={`/blog/${urlTitle}`}
          css={`
            text-decoration: none;
            color: inherit;
            display: flex;
            flex-direction: column;
            height: 100%;
          `}
        >
          <CardMedia>
            <GatsbyImage
              image={getImage(heroImage)}
              title={heroImage.title}
              alt={heroImage.description || ""}
            />
          </CardMedia>
          <CardHeader
            title={
              <Typography
                css={`
                  ${scale(0.4)};
                  margin-bottom: ${rhythm(1 / 4)};
                `}
                variant={"h3"}
              >
                {title}
              </Typography>
            }
            subheader={
              <div
                css={`
                  display: flex;
                  justify-content: space-between;
                `}
              >
                <Typography color={"textSecondary"} variant={"subtitle1"}>
                  {date}
                </Typography>
              </div>
            }
          />
          <CardContent>{rendered}</CardContent>
        </Link>
      </CardActionArea>
      <CardActions
        css={`
          align-items: flex-end;
          flex-grow: 1;
        `}
      >
        <Chip
          variant={"outlined"}
          onClick={() => handleClickChip(type)}
          label={displayLabel}
        />
      </CardActions>
    </MCard>
  )
}
export default BlogEntry
