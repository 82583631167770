import React, { useCallback, useMemo, useState } from "react"
import _ from "lodash/fp"
import without from "lodash/without"
import NavigationLayout from "../components/layout/mainLayout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../components/Seo"
import Hidden from "@material-ui/core/Hidden"
import parse from "date-fns/parse"
import { rhythm, scale } from "../utilities/typography"
import { graphql } from "gatsby"
import styled from "styled-components"
import uniq from "lodash/uniq"
import { formatDate } from "../utilities/formatDate"
import DoneIcon from "@material-ui/icons/Done"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Chip from "@material-ui/core/Chip"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import BlogEntry from "../components/BlogEntry"

const BannerHeader = ({ image }) => {
  return (
    <Box p={[2, 3, 4]}>
      <div
        css={`
          display: flex;
          justify-content: start;
          align-items: center;
        `}
      >
        <Hidden mdDown>
          <Box mx={[2, 3, 3]}>
            <GatsbyImage
              image={getImage(image)}
              style={{ borderRadius: "100%" }}
              css={`
                min-width: 300px;
                min-height: 300px;
              `}
              alt="Illenau Achern"
            />
          </Box>
        </Hidden>
        <Hidden mdDown>
          <div>
            <Typography
              variant="h1"
              css={`
                ${scale(2.25)}
                font-weight: 700;
                color: ${(props) => {
                  return props.theme.colors.lci_yellow
                }};
              `}
            >
              LIONS Achern
            </Typography>
            <Typography
              css={`
                ${scale(1)}
              `}
              variant="subtitle1"
            >
              Neuigkeiten & Aktivitäten
            </Typography>
          </div>
        </Hidden>
        <Hidden lgUp>
          <div>
            <Typography
              variant="h1"
              css={`
                ${scale(1)}
                font-weight: 700;
                color: ${(props) => {
                  return props.theme.colors.lci_yellow
                }};
              `}
            >
              LIONS Achern
            </Typography>
            <Typography
              css={`
                ${scale(0.5)}
              `}
              variant="subtitle1"
            >
              Neuigkeiten & Aktivitäten
            </Typography>
          </div>
        </Hidden>
      </div>
    </Box>
  )
}

const PageContent = styled(Container)`
  display: grid;
  margin: auto;
  grid-gap: ${rhythm(2)};
  grid-template-columns: repeat(auto-fill, minmax(${rhythm(13)}, 1fr));

  @media (min-width: ${(props) => props.theme.breakpoints.medium}px) {
    grid-template-columns: repeat(auto-fill, minmax(${rhythm(17)}, 1fr));
  }
`

const Filter = ({
  options = [],
  selected,
  handleDelete,
  handleClickOption,
}) => {
  return (
    <Grid container spacing={1}>
      <Grid
        item
        css={`
          display: flex;
          align-items: center;
        `}
      >
        <Typography variant={"body2"}>Aktive Filter:</Typography>
      </Grid>
      {options.map((v) => {
        return (
          <Grid item key={v.id}>
            {selected.includes(v.id) ? (
              <Chip
                clickable
                icon={<DoneIcon />}
                label={v.label}
                variant={"outlined"}
                onDelete={() => handleDelete(v.id)}
                color={"primary"}
              />
            ) : (
              <Chip
                clickable
                label={v.label}
                variant={"outlined"}
                onClick={() => handleClickOption(v.id)}
              />
            )}
          </Grid>
        )
      })}
    </Grid>
  )
}

const displayLabel = (type) => {
  switch (type) {
    case "activity":
      return "Aktivität"
    case "normal":
      return "Neuigkeit"
    default:
      return "Neuigkeit"
  }
}

const parseDate = (str) => parse(str, "dd/MM/yyyy", new Date())

const IndexPage = ({
  data: {
    image,
    allContentfulPost: { nodes: posts },
  },
}) => {
  const withExcerptsSorted = posts
    .map((p) => ({
      ...p,
      date: p.date ? formatDate(p.date) : null,
      key: p.title,
      heroImage: p.heroImage,
      text: p,
      displayLabel: displayLabel(p.type),
    }))
    .sort((a, b) => {
      return parseDate(a.date) < parseDate(b.date) ? 1 : -1
    })

  const types = uniq(withExcerptsSorted.map((v) => v.type)).map((v) => ({
    label: displayLabel(v),
    id: v,
  }))

  const [active, setActive] = useState([])
  const handleClickOption = useCallback(
    (id) => {
      setActive(active.concat(id))
    },
    [active]
  )
  const handleDeleteOption = useCallback(
    (id) => {
      setActive(without(active, id))
    },
    [active]
  )

  const sorted = useMemo(() => {
    if (_.size(active) === 0) return withExcerptsSorted

    return _.filter((x) => active.includes(x.type), withExcerptsSorted)
  }, [active, withExcerptsSorted])

  return (
    <NavigationLayout>
      <Seo title="Neuigkeiten" />
      <Container maxWidth="lg" disableGutters>
        <BannerHeader image={image} />
        <Box px={[2,4,4]} py={[1,2,2]}>
          <Filter
            options={types}
            handleClickOption={handleClickOption}
            selected={active}
            handleDelete={handleDeleteOption}
          />
        </Box>
      </Container>
      <PageContent maxWidth={"lg"}>
        {sorted.map((x) => (
          <BlogEntry {...x} handleClickChip={handleClickOption} />
        ))}
      </PageContent>
    </NavigationLayout>
  )
}

export const query = graphql`
  query index {
    image: imageSharp(original: { src: { regex: "/home3.*/" } }) {
      gatsbyImageData(
        layout: FIXED
        width: 300
        height: 300
        placeholder: BLURRED
        transformOptions: { cropFocus: CENTER }
        formats: [AUTO, WEBP]
      )
    }
    allContentfulPost {
      nodes {
        date
        body {
          raw
          references {
            ... on ContentfulAsset {
              contentful_id
              __typename
              gatsbyImageData
            }
            contentful_id
            title
            fixed {
              src
            }
          }
        }
        type
        heroImage {
          gatsbyImageData(
            aspectRatio: 1.77
            layout: FULL_WIDTH
            formats: [AUTO, WEBP]
          )
        }
        urlTitle
        title
      }
    }
  }
`

export default IndexPage
