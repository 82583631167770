import parseISO from "date-fns/parseISO"
import format from "date-fns/format"
import { de } from "date-fns/locale"

export const formatDate = (date) => {
  // Parse and format date
  const parsedDate = date ? parseISO(date) : null
  // const parsedDate = parse(date, "yyyy-MM-dd", new Date())
  return format(parsedDate, "dd/MM/yyyy", {
    locale: de,
  })
}

export const formatDateWithMinutes = (date) => {
  // Parse and format date
  const parsedDate = date ? parseISO(date) : null
  // const parsedDate = parse(date, "yyyy-MM-dd", new Date())
  return format(parsedDate, "dd/MM/yyyy HH:mm", {
    locale: de,
  })
}
